@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;900&display=swap');

body{
    margin: 0;
    background-color: #f2f0f0 !important;
}

i{
    color: white;
}

div{
    font-family: 'Montserrat', sans-serif !important;
}

.cotizacion-box{
    background-color: white;
    padding: 35px 20px 20px 20px;
    margin: 20px !important;
}

.mynavbar {
    background-color: #eaeded !important;
}

.navbar-brand {
    margin-left: 200px;
}

.nav-item{
    margin-left: 25px;
}

.searchTitle {
    margin-bottom: 25px;
}

.middleBannerHome {
    background-color: black;
}

.descuentos{
    text-align: center;
}

.carousel-indicators{
    margin-bottom: 5rem !important;
}

.cards-slider{
    padding-top: 8rem;
    background-color: #bbbbbb;
}

.icono-cuenta{
    padding-left: 1vw;
    border-left: 2px solid #777777;
}

.header-favorito{
    border-left: 2px solid #777777;
    border-right: 2px solid #777777;
    text-align: center;
}

.header-compras{
    text-align: center;
}

.producto {
    text-align: center;
    padding-left: 9vw;
}

.lineas-quienesSomos{
    background-color: #3266b1;
    width: 6rem;
    height: 4px;
}


.linea-griGrad{
    width: 80vw;
    height: 2px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(154,154,154,1) 50%, rgba(0,0,0,1) 100%);
    margin: 0px auto;
}

.border-gradient {
    border-right: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(rgba(0,0,0,1) 10%, rgba(154,154,154,1) 50%, rgba(0,0,0,1) 100%) 
}

.linea-gradiente-cotizacion{
    width: 500px;
    height: 2px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, white 0%, #FFCA2A 50%, white 100%);
    margin: 0px auto;
}

.linea-gradiente-cotizacion-celular{
    width: 150px;
    height: 2px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, white 0%, #FFCA2A 50%, white 100%);
    margin: 0px auto;
}

.breadcrumb{
    background-color: transparent !important;
}

.filtro-anno{
    padding-left: 4px !important;
    padding-right: 4px !important;
}



.prod-catalogo{
    margin-bottom: 4vh;
}

.datos-productos-box{
    background-color: white;
    margin: 0px !important;
}

.auto-quienessomos{
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    width: 100% ;
}

/*PAGINACION*/
.pagination > .active > a
{
    color: white;
    background-color: #F10C0C !Important;
    border: solid 1px #F10C0C !Important;
    border-radius: 50% !important;
}

/*estilos para responsive entre dispositivos(PC;movil)*/
.cartaGrande{
    width:260px;
    height:350px;
    margin-bottom:25px; 
    margin-left:18px;
}

.botonRojo{
    background-color: #f10c0c !important;
}

.botonGrande {
    position:absolute;
    bottom:50px;
    left:70px;
    margin:auto;
    background-color: #f10c0c !important;
}

.textoGrande {
    font-size:0.9rem;
}

.imagenCartaGrande{
    height:135px;
    width:180px !important;
    margin:auto; 
    padding-top:10px;
}

.cartaMedio{
    width:200px;
    height:312px;
    margin-bottom:25px; 
    margin-left:18px;
}

.botonMedio{
    position:absolute;
    bottom:20px;
    left:50px;
    margin:auto;
}

.textoMedio{
    font-size:0.8rem;
}

.imagenCartaMedio{
    height:135px ;
    width:180px !important; 
    margin:auto; 
    padding-top:10px;
}

.cartaMovil{
    width:140px;
    height:195px;
    margin-bottom:25px; 
}

.botonMedioMovil{
    position:absolute;
    bottom:20px; 
    left:50px; 
    margin:auto;
}

.textoMovil {
    font-Size:0.8rem;
}

.imagenCartaMovil{
    height:100px; 
    width:100px !important; 
    margin:auto; 
    padding-top:2px;
}

/*VISTA DE PRODUCTO*/
/*vista grande y media*/
.imagenProdGrande{
    max-width:500px;
    max-height:500px;
}

.imagenProdMedio{
    max-width:500px;
    max-height:500px;
}

.datosBoxProdGrande{
    padding-top:40px;
    padding-bottom:40px;
}

.textoInformacionProdGrande{
    display :flex;
    align-items:center;
    padding-left:5rem;
}

.textoInformacion2ProdGrande{
    padding-top:30px ;
    padding-bottom:30px;
    background-color:#f0ececb4;
}

.cotizarProdGrande{
    padding-top:20px;
    padding-bottom:20px;
    background-color:#f0ececb4;
}
/*vista movil*/
.imagenProdMovil{
    height:200px; 
    width:200px; 
    margin:auto; 
    padding-top:2px
}

.datosBoxProdMovil{
    padding-top:2px; 
    padding-bottom:2px;
}

.textoInformacionProdMovil{
    display :flex; 
    align-items:center; 
    padding-left:5px;
}

.textoInformacion2ProdMovil{
    padding-top:2px; 
    padding-bottom:0px; 
    background-color:#f0ececb4;
    width:200px;
    font-size:0.7rem;
    margin-bottom:5px;
}

.cotizarProdMovil{
    padding-top:5px;
    padding-bottom:5px;
    background-color:#f0ececb4;
    width:200px;
}