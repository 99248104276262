
.footer{
    font-family: 'Montserrat', sans-serif !important;
    background-color: black;
    color: white;
    padding-bottom: 2px;
    padding-top: 40px;
}


.footer-subrayados{
    width: 6rem;
    height: 4px;
    background-color: #3266b1;
    margin-bottom: 2rem;
}

.footer-form{
    margin-bottom: 20px !important;
}

.footer-logoFb{
    width: 32px;
    height: 60px;
}

.footer-logoInsta{
    width: 60px;
    height: 60px;
}

.footer-vendedor{
    text-align: right;
}

.footer-vend-titulo{
    text-align: center;
}

.direccion-final{
    font-size: 0.8rem !important;
    margin-top: 30px;
}

.col-sm{
    font-size: 0.86vw;
    text-align: inherit;
    letter-spacing: 0.1em;
    column-width: 310px;
}

@media(max-width: 426px){
    .col-sm{
        font-size: 2vw;
    }
}

@media(width: 1024px) {
    .col-sm{
        font-size: 0.75vw;
    }
}